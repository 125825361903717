@import '../../styles/base';

.LoginPageContent {
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    >li {
      margin-top: 1rem;
      &:first-child {
        margin-top: 0;
      }
    }

    a {
      text-decoration: none;
      color: color('blue');
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
}
@import '../../styles/base';

.AccountInfoForm {
  padding-top: size(ml);
  display: flex;
  flex-direction: column;

  p strong {
    font-size: 1.6rem;

    @media #{$desktop-lg} {
      font-size: 1.8rem;
    }
  }

  .AccountPersonalInfo {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: size(md);

    @media #{$tablet} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media #{$tablet-lg} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.AccountPersonalInfo {
  input {
    border-bottom: none;
    border: 2px solid color('dark', .21);
    background-color: color('gray', .3);
    padding: .52em;
  }
}

.AccountInfoFormLinks {
  color: color('blue');
  font-weight: 700;
  font-size: 1.4rem;

  @media #{$only-phone} {
    display: flex;
    flex-direction: column;

    > * {
      + * {
        margin-top: .5em;
      }
    }
  }

  @media #{$desktop-lg} {
    font-size: 1.6rem;
  }

  a {
    text-decoration: none;

    @media #{$tablet} {
      margin-right: 2em;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.AccountInfoFormToggle,
.AccountInfoFormLinks {
  padding: 1.6em;
  background-color: color('teal', .1);
}
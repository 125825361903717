@import '../../styles/base';

.BaseInput {
  display: block;
  
  &.hasFocus {
    color: color('dark');
  }
  label {
    display: block;
    font-weight: 700;
    color: color('blue');
    margin-bottom: .5em;
    em {
      font-style: normal;
      color: color('yellow');
    }
  }
  input {
    display: block;
    color: inherit;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid color('teal');
    border-radius: 0;
    width: 100%;
    padding: .62em 0;
    box-sizing: border-box;
    @media #{$only-phone} {
      font-size: 1.4rem;
    }
    .mouse & {
      &:hover {
        border-bottom-style: solid;
      }
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid color('teal');
    }
    &::placeholder {
      font-weight: 400;
      color: color('dark',0.2)
    }
  }
}

@import '../../styles/base';

.BaseSpacer {
  &[data-size="sm"] {
    width: size(sm);
    height: size(sm);
  }
  &[data-size="md"] {
    width: size(md);
    height: size(md);
  }
  &[data-size="ml"] {
    width: size(ml);
    height: size(ml);
  }
  &[data-size="lg"] {
    width: size(lg);
    height: size(lg);
  }
  &[data-size="xl"] {
    width: size(xl);
    height: size(xl);
  }
  &[data-size="pageMargin"] {
    width: size(pageMargin);
    height: size(pageMargin);
  }
}
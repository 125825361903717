@import '../../styles/base';

.PageAccountManagement {
  min-height: 100vh;
}

.PageAccountManagementContent {
  display: flex;

  .PageContent {
    display: flex;
    flex-direction: column;
    max-width: 65em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .PageContentHeader {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.PageAccountManagementHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid color('dark', .1);
  padding-bottom: size(ml);

  @media #{$only-phone} {
    flex-direction: column;
    align-items: flex-start;
    >* {
      +* {
        margin-top: 1em;
      }
    }
    button {
      width: fit-content;
    }
  }

}
@import '../../styles/base';

.ReportDownloader {
  &.disallow {
    color: color("red", 0.5);

    .BaseButton svg {
      color: color("red", 0.6);
    }
  }
}

@import '../../styles/base';

.SingleReportContent {
  padding: 2em;
}

.SingleReportContainer {
  div {
    >* {
      +* {
        margin-top: .5em;
      }
    }
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;

    @media #{$desktop} {
      font-size: 1.6rem;
    }
  }

  p:empty {
    display: none;
  }

  h2,
  h2 a {
    font-size: 1.8rem;
  }

  a {
    color: color('blue');
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-left: 1.5rem;
  }

  ul>li {
    margin-top: .5em;
  }

  .FallbackVideo {
    margin-bottom: 1.5em;
    background-color: color('dark', .5);
    color: color('red', .8);
    width: 100%;
    aspect-ratio: 16 / 9;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  img {
    display: block;
  }
}
@import '../../styles/base';

.SearchBar {
  position: relative;
  .BaseInput {
    width: 100%;
    background-color: color('gray',.3);
    input {
      padding: 0 1em;
      appearance: none;
      width: 100%;
      box-sizing: border-box;
      color: color('dark');
      height: 3em;
      border-bottom: unset;
      &::placeholder {
        color: color('dark');
        font-size: 1.4rem;
      }
    }
  }
  .SearchBarIcon {
    padding-right: 1em;
    position: absolute;
    right: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: inherit;
  }
}
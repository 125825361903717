@import '../../styles/base';

.InternalSiteMarker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  user-select: none;
  z-index: 10001;
  &[data-hostname="localhost"] {
    @include setPrimaryColor('green');
  }
  &[data-hostname="beta.carraighill.com"] {
    @include setPrimaryColor('red');
  }
  @media print {
    display: none;
  }
  .ColorTag {
    font-size: 8px;
    font-weight: 900;
    margin-top: -1px;
  }
}
.InternalSiteMarkerInner {
  font-size: 8px;
  padding: 2px 4px 1px 4px;
  background-color: color('green');
  color: color('white');
  .--cypress & {
    background-color: #04c38e;
    color: white;
  }
  text-transform: uppercase;
  font-weight: 900;
  display: block;
  border-radius: 0 0 3px 3px;
  letter-spacing: .1em;
  > * {
    + * {
      margin-left: .5em;
    }
  }
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: color('green');
  }
}
.InternalSiteMarker {
&[data-hostname="www.beta.carraighill.com"], &[data-hostname="www.carraighill.axon.host"] {
  .InternalSiteMarkerInner {
    background-color: color('red');
    &:before {
      background-color: color('red');
    }
  }
}
}
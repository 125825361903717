@import '../../styles/base';

.BaseButton {
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1.5em;
  background-color: color('teal');
  border: 2px solid color('white',0.4);
  color: color('white');
  font-weight: 700;
  cursor: pointer;
  font-size: 1.4rem;
  min-width: 8em;
  &.disabled,
  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }
  &:hover {
    opacity: .8;
  }
}
@import '../../styles/base';

.ReportEntry {
  border-bottom: 1px solid color('dark', .1);

  h2,
  h3,
  p,
  li {
    font-size: 1.4rem;
    line-height: 1.4;

    @media #{$desktop-lg} {
      font-size: 1.6rem;
    }
  }
}

.ReportEntryHeader {
  cursor: pointer;
  display: grid;
  grid-gap: .5em;
  grid-auto-rows: auto;
  padding: size(md) 0;
  grid-template-columns: minmax(10em, 1fr) auto;

  @media #{$tablet} {

    grid-template-columns: minmax(19em, 1fr) auto;
  }
}

.ReportEntryHeaderIndex,
.ReportEntryHeaderControls {
  display: flex;
  align-items: flex-start;
}

.ReportEntryHeaderIndex {
  flex-direction: column;

  >* {
    +* {
      margin-left: 0;
      margin-top: 1em;
    }
  }

  @media #{$desktop} {
    flex-direction: row;

    >* {
      +* {
        margin-top: 0em;
        margin-left: .5em;
      }
    }
  }

  p {
    font-weight: 600;
  }
}

.ReportEntryTitle {
  display: flex;
  flex-direction: column;

  @media #{$tablet} {
    flex-direction: row;

    p {
      margin-left: .25em;
    }
  }

  @media #{$desktop} {
    flex-direction: column;

    p {
      margin-left: 0;
    }
  }

  @media #{$desktop-md} {
    flex-direction: row;

    p {
      margin-left: .25em;
    }
  }
}

.ReportEntryHeaderControls {
  >* {
    +* {
      margin-left: .5em;

      @media #{$desktop} {
        margin-left: 1em;
      }
    }
  }

  .IconUp {
    transform: rotate(180deg);
  }

  .LoadingIndicator {
    cursor: default;
  }
}

.ReportEntryPublishedDate {
  color: color('dark', .5);
  font-weight: 500;
  flex: 0 0 auto;

  @media #{$desktop} {
    flex: 0 0 6em;
    // text-align: right;
  }
}

.ReportEntryHeaderOpen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: size(md);

  @media #{$desktop} {
    flex-direction: row;
  }
}

.ReportContentContainer {
  padding-bottom: size(md);
  padding-right: size(md);

  @media #{$desktop} {
    padding-left: 9.2rem;
    // flex: 0 0 40rem;
  }

  @media #{$desktop-lg} {
    // flex: 0 0 60rem;
    padding-left: 10.55rem;
  }

  a {
    text-decoration: none;
    color: color('blue');
    font-weight: 700;
    font-size: 1.4rem;

    @media #{$desktop-lg} {
      font-size: 1.6rem;
    }
  }

  h1,
  strong,
  h2,
  p,
  li {
    color: color('dark', .6);
  }

  h2,
  p,
  li {
    font-weight: 500
  }

  .ReportContent {
    @include clampLines(5);
    color: color('dark', .6);

    @media #{$desktop-xl} {
      width: 100rem;
    }

    p:empty {
      display: none;
    }
  }

  blockquote {
    margin: 0;
  }


  ul,
  ol {
    margin: 0;
    padding-left: 2rem;
  }

  ul,
  ol>li {
    margin-top: .5em;
  }
}

.ReportEntryHeaderSubtitle,
.ReportEntryHeaderSubtitle>strong {
  font-weight: 600;
  color: color('dark', .6);
  font-size: 1.4rem;
  line-height: 1.4;

  @media #{$desktop-lg} {
    font-size: 1.6rem;
  }
}

.ReportEntryHeaderControls {
  .PlayVideoIconWrapper {
    &.disallow {
      color: color("red", 0.5);
    }
  }
}

html.safari .ReportContent {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height:11em;
}
@import '../../styles/base';

@keyframes PageReportSingleBackdropEnter {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes PageReportSingleEnter {
  from { transform: translateX(100%) }
  to { transform: translateX(0) }
}
.PageReportSingleLayer {
  position: fixed;
  top: $AppNavBarHeight;
  left: 0;
  right: 0;
  bottom: 0;
}
.PageReportBackdrop {
  background-color: color('dark', .2);
  position: fixed;
  top: inherit;
  left: 0;
  right: 0;
  bottom: 0;
  animation: PageReportSingleBackdropEnter .5s;
  z-index: 9;
}

.PageReportSingle {
  position: fixed;
  top: inherit;
  right: 0;
  bottom: 0;
  background-color: color('white');
  box-shadow: 0 1em 3em color('dark',.2);
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  animation: PageReportSingleEnter .3s cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 10;
  @media #{$tablet} {
    max-width: 50rem;
  }
  @media #{$desktop} {
    max-width: 62rem;
  }
  @media #{$desktop-md} {
    max-width: 80rem;
  }
  @media #{$desktop-lg} {
    max-width: 85rem;
  }
  @media #{$desktop-xl} {
    max-width: 125rem;
  }


  .PageReportNotFound {
    min-height: 95%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$desktop-lg} {
      min-height: 100%;
    }
    span {
      font-weight: 700;
      font-size: 1.6rem;
      @media #{$desktop-lg} {
        font-size: 2rem;
      }
    }
  }
}

@import '../../styles/base';

.PageContentHeader {
  border-bottom: 1px solid color('dark', .1);
  padding-bottom: size(lg);
}
.PageContentHeaderTitle {
  font-weight: 300;
  font-size: 3.4rem;
  margin-bottom: .25em;
  @media #{$desktop-lg} {
    font-size: 3.6rem;
  }
}
.PageContentHeaderAfterTitle {
  color: color('dark',0.6);
  font-weight: 400;
  font-size: 1.4rem;
}
@import '../../styles/base';

.AppFooter {
  padding: size(lg);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  border-top: 1px solid color('blue', .3);
}

.AppFooterInner {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  >* {
    +* {
      margin-top: 1.5em;
    }
  }


  @media #{$desktop-lg} {
    flex-direction: row;

    >* {
      +* {
        margin-left: 1em;
        margin-top: 0;
      }
    }
  }
}

.AppFooter__Content {
  flex: 0 0 66.66%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em;
  @media #{$only-phone} {
    grid-gap: 1.5em;
  }

  @media #{$upto-phone-md} {
    grid-template-columns: repeat(1, 1fr);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: wrap;
    color: color('blue');

    >li {
      display: inline;
      margin-right: .5em;

      @media #{$desktop-lg} {
        margin-right: 0;
      }

      +li {

        // @media #{$desktop-lg} {
        &:before {
          content: '|';
          // margin-left: .5em;
          margin-right: .5em;
          // }
        }
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.AppFooter__Content--Address {
  a {
    text-decoration: underline;
  }

  address {
    font-style: normal;

    span {
      display: block;
    }
  }
}

.AppFooter__LogoBox {
  flex: 0 0 33.33%;
}
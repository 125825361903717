@import '../../styles/base';

.ReportImageWrapper {
 
  @media #{$tablet} {
    flex: 0 0 11rem;
  }

  .ReportImage, .FallbackImage {
    &.landscape {
      width: 11em;
      aspect-ratio: 4 / 3;
    }

    &.portrait {
      width: 8em;
      aspect-ratio: 3 / 4;
    }

    &.even {
      width: 10em;
      aspect-ratio: 1 / 1;
    }
  }

  .ReportImage {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    @media #{$desktop} {
      background-position: right;
    }
  }

  .FallbackImage {
    padding: 0.2em;
    box-sizing: border-box;
    display: none;
    background-color: color('dark', .8);
    color: color('white');
    justify-content: center;
    align-items: center;
    text-align: center;

    &.active {
      display: flex;
    }
  }
}

@import '../../styles/base';

.ForgotPasswordForm {
  max-width: 50rem;

  form {
    display: flex;
    flex-direction: column;
  }

  .CarraighillLogo {
    padding-bottom: size(md);
    height: 3.2em;
    width: 10em;
    font-size: 2.2rem;
    @media #{$tablet} {
      font-size: 3.2rem;
    }
  }
}

.ForgotPasswordFormSubmission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: .5em;
  
  .BaseButton {
    @media #{$upto-phone-lg} {
      min-width: unset;
      font-size: 1.2rem;
    }
  }
}
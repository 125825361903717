@import '../../styles/base';

.PageContent {
  flex: 1 1 auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  height: 100%;
}
.PageContentInner {
  min-height: 80vh;
  padding: size(lg) size(xl);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  // height: 100%;
  @media #{$tablet} {
    padding: size(lg);
  }
  @media #{$desktop-lg} {
    padding: size(lg) size(xl);
  }
}
@import '../../styles/base';

.BaseCheckbox {
  position: relative;
  overflow: hidden;
  input {
    position: absolute;
    left: -2em;
  }
  label {
    display: flex;
    align-items: center;
  }
  &.isSelected {
    .BaseCheckboxLabelMarker {
      color: color('white');
      background-color: color('teal');
      &:before {
        content: '✓';
      }
    }
  }
}

.BaseCheckboxLabelMarker {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  @media #{$tablet} {
    width: 1.2em;
    height: 1.2em;
  }
  margin-right: .5em;
  vertical-align: middle;
  border: 1px solid color('teal');
  border-radius: 3px;
  flex: 0 0 auto;
}

.BaseCheckboxLabelContent {
  font-size: 1.4rem;
  font-weight: 500;
}
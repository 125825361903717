@import '../../styles/base';

.SingleReportHeader {
  background-color: color('lightgray');

  @supports (backdrop-filter: blur(1em)) {
    background-color: color('lightgray', .8);
    backdrop-filter: blur(1em);
  }

  padding: 2em;
  display: flex;
  justify-content: space-between;
  @media #{$tablet} {
  position: sticky;
  top: 0;
  z-index: 1;
  }

  a {
    text-decoration: none;
  }

  .BaseButton {
    text-transform: uppercase;
    border: 2px solid color('white', 0.29);
    @media #{$only-phone} {
      padding: .5rem;
      font-size: 1rem;
    }
    @media #{$tablet} {
      padding: .25rem;
      font-size: 1.2rem;
      }
    @media #{$desktop} {
    padding: .8rem;
    }

    div {
      display: flex;
      align-items: center;
    }

    // svg {
    //   margin-top: -0.19em;
    //   margin-right: .25em;
    // }
  }
  .FallbackImage {
    &.active {
      height: 11.5em;
      @media #{$desktop-lg} {
        height: unset;
      }
    }
  }

}

.SingleReportHeaderCategoryName {
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.19;
  font-weight: 700;
}

.SingleReportHeaderTitle {
  font-size: 1.8rem;
  @media #{$phone-lg} {
    font-size: 1.6rem;
  }
  @media #{$tablet} {
    font-size: 1.8rem;
  }
  @media #{$desktop} {
    font-size: 2rem;
  }

  @media #{$desktop-lg} {
    font-size: 2.2rem;
  }
}

.SingleReportHeaderPublishedDate {
  font-weight: 500;
  color: color('dark');
  font-size: 1.2rem;

  @media #{$desktop-lg} {
    font-size: 1.4rem;
  }
}

.CloseReport {
  margin-left: .75em;
  display: block;
  color: inherit;
  align-self: flex-start;

  &:hover {
    filter: brightness(1.1);
    background-color: color('dark', .01);
  }
}

.SingleReportHeaderContentContainer {
  display: flex;
  width: 100%;

  @media #{$upto-phone-lg} {
    flex-direction: column;
  }
  .ReportImage {
    height: 100%;
    aspect-ratio: auto !important;
    // width: 100%;
    background-position: unset;
    @media #{$upto-phone-lg} {
      width: 8em;
      background-position: unset;
    }
  }
}

.SingleReportHeaderContent {
  margin-left: 1em;

  @media #{$tablet} {
    margin: 0 1em;
  }
  @media #{$upto-phone-lg} {
    margin: 0;
    margin-top: 1em;
  }

  .BaseSpacer {
    @media #{$upto-phone-lg} {
      height: .5em !important;
      width: .5em !important;
    }
  }
  .ReportDownloader .BaseButton{
    @media #{$desktop} {
      min-width: 15em;
    }
  }
}
@import '../../styles/base';


@keyframes loadingBlockerEnter {
  from {
    transform: scale(.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes loadingBlockerExit {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.05);
    opacity: 0;
  }
}
@keyframes loadingBlockerBackdropEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes loadingBlockerBackdropExit {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.LoadingBlocker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.LoadingBlockerBackdrop {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: color("white", .6);
  animation: loadingBlockerBackdropEnter .1s;
}

.LoadingBlockerInner {
  animation: loadingBlockerEnter .1s;
  padding: 1em;
  border-radius: 10em;
  background-color: color("gray");
  border: 2px solid color("dark", .2);
  margin-bottom: 1em;
  .LoadingIndicator {
    color: color(#0A1D2A);
  }
}
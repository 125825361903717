.FooterLogoSetImageGrid {
  display: flex;
  flex-flow: wrap;
  margin-right: .5em;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: .5em;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  img {
    display: block;
    height: auto;
  }
}
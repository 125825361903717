@import '../../styles/base';

@keyframes dialogEnter {
  from {
    transform: scale(.95);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes dialogExit {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.05);
    opacity: 0;
  }
}

@keyframes dialogEnterSimple {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dialogExitSimple {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes dialogBackdropEnter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dialogBackdropExit {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.DialogOld {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DialogOldBackdrop {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: color('dark', .19);
  animation: dialogBackdropEnter .38s;

  .closing & {
    animation: dialogBackdropExit .38s;
  }
}

.DialogOldLayer {
  position: relative;
  background-color: color('white');
  border: 0;
  box-shadow: 0 1.5em 3em color('dark', .19);
  animation: dialogEnter .1s cubic-bezier(.38, 0, 0, 2);
  max-width: 50rem;
  overflow: hidden;
  width: 90vw;
  max-height: 25em;
  backdrop-filter: blur(2em);
  border-top: 3px color('teal') solid;
  border-radius: .5em;
  margin-bottom: 1.5em;
}

.DialogOldHeader {
  padding: 1.5em 1.5em 0;

  +.DialogOldFooter {
    margin-top: 1.5em;
  }

  @media #{$desktop} {
    // padding-right: 6.18em;
  }
}

h1.DialogOldHeading {
  font-family: inherit;
  font-size: 116%;
  color: color('teal');
  font-weight: 700;
  margin: 0;
  text-align: center;
}

.DialogOldBody {
  padding: 1em 1.5em 1.5em;
  font-family: font(ui);
  line-height: 1.19;

  a {
    color: color('blue');
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    font-size: 1.6rem;
    text-align: center;
    +p {
      margin-top: .5em;
    }
  }
}

.DialogOldFooter {
  display: flex;

  @media #{$upto-tablet} {
    >* {
      flex: 1 1 auto;
    }
  }

  @media #{$tablet} {
    justify-content: flex-end;
    // padding: 0 1.3em;

    .BaseButton {
      // margin: 0 .2em;
      border: none;
      // border-radius: .62em;
      // border-bottom-left-radius: 0;
      // border-bottom-right-radius: 0;
      width: 100%;
    }
  }

  .negative {
    background-color: color('teal', .1);
    color: color('teal');
  }
}

.ErrorRenderer {
  background-color: color('red', .05);
  .DialogOldLayer {
    border-top: 3px color('red', .6) solid;
  }
  .BaseButton {
    background-color: color('red', .8);
  }
  .DialogOldHeading {
    color: color('red', .8);
  }
}

.AttentionRenderer {
  background-color: color('yellow', .05);
  .DialogOldLayer {
    border-top: 3px color('yellow', .6) solid;
  }
  .BaseButton {
    background-color: color('yellow', .8);
  }
  .DialogOldHeading {
    color: color('yellow', .8);
  }
}

.SuccessRenderer {
  background-color: color('green', .05);
  .DialogOldLayer {
    border-top: 3px color('green', .6) solid;
  }
  .BaseButton {
    background-color: color('green', .8);
  }
  .DialogOldHeading {
    color: color('green', .8);
  }
}
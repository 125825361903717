* {
  box-sizing: content-box;
}

:root {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans',-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Ubuntu","Helvetica Neue",sans-serif;
  min-height: var(--AppHeight);
  width: 100vw;
  --safeMarginLeft: #{size(md)};
  --safeMarginRight: #{size(md)};
  transition: background-color 1s,
  color 1s;
  --TitleBarHeight: 5rem;

  @media #{$tablet} {
    --TitleBarHeight: 0px;
  }

  &[data-orientation="landscape"] {
    --safeMarginLeft: calc(.5em + env(safe-area-inset-left));
    --safeMarginRight: calc(.5em + env(safe-area-inset-right));
  }

  &.no-scroll {
    overflow: hidden;
    width: var(--AppWidth);
    height: var(--AppHeight);
  }
  &.no-select {
    user-select: none;
  }
}

body {
  font-size: 1.6rem;
  line-height: 1.2;
}

img {
  max-width: 100%;
} 

figure {
  margin: 0;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 700;
}

p {
  margin: 0;
}
::selection {
  background-color: color('teal',.75);
  color: color('white');
}
@import '../../styles/base';

@keyframes LoadingIndicatorEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes LoadingIndicatorDotJump {
  from { transform: translateY(30%)}
  to { transform: translateY(-30%)}
}
.LoadingIndicator {
  opacity: 0;
  animation: LoadingIndicatorEnter .19s forwards;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  .--cypress & {
    opacity: 1;
    animation: none;
  }
  span {
    display: block;
    width: 4px;
    height: 4px;
    margin: 2px;
    border-radius: 50%;
    background-color: #0A1D2A;
    animation: LoadingIndicatorDotJump .25s .2s infinite alternate;
    &:nth-child(1) { animation-delay: .1s; }
    &:nth-child(2) { animation-delay: .2s; }
    &:nth-child(3) { animation-delay: .3s; }
  }
}
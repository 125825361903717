@import '../../styles/base';

.AppNavBar {
  position: relative;
  width: 100%;
  height: $AppNavBarHeight;
}

.AppNavBarInner {
  padding: 0 1.2em;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  width: 100%;
  background-color: color('dark');
  color: color('white');
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: env(safe-area-inset-top);
}

.AppNavBarLogoLink,
.AppNavBarIconLink {
  display: block;
  color: inherit;
  padding: 1em;
  align-self: center;
}

.AppNavBarIconList {
  display: flex;
  padding-right: 1em;
}

.AppNavBarIconLink {
  padding: .5em;
}
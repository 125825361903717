@import '../../styles/base';


.AuthPage {
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  @media #{$desktop-lg} {
    min-height: var(--AppHeight);
    height: 100vh;
  }

  footer {
    margin-top: auto;
  }
}

.AuthPageContainer {
  padding: size(xl);
  padding-top: size(xl);
  position: relative;
  // overflow: hidden;

  .LoginBackgroundImage {
    position: absolute;
    top: 0;
    left: 33%;
    z-index: -1;

    @media #{$upto-tablet} {
      left: 0;
    }
  }
}
@import '../../styles/base';

.BaseToggle {
  display: flex;
  position: relative;
  transform: translateZ(0);
  user-select: none;
  [type="radio"],
  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  &.fullWidth {
    display: block;
    .BaseToggleInner {
      display: flex;
    }
  }
  &.BaseToggle {
    .BaseToggleBox {
      width: 25px * 2;
      height: 28px;
    }
    .BaseToggleBoxMarker {
      border-radius: 14px;
      width: 24px;
      height: 24px;
      background-color: color('white');
    }
    &.true {
      .BaseToggleBoxMarker {
        right: 1px;
        background-color: color('white');
      }
    }
    &.false {
      .BaseToggleBoxMarker {
        right: 50%;
        // opacity: .5;
      }
    }
  }
  &.BaseToggleCheckbox,
  &.BaseToggleRadio {
    .BaseToggleBox {
      width: 50px;
      height: 28px;
    }
    .BaseToggleBoxMarker {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    &.true {
      .BaseToggleBoxMarker {
        opacity: 1;
      }
    }
  }
  &.BaseToggleCheckbox {
    .BaseToggleBox {
      border-radius: .3em;
    }
  }
  &.BaseToggleRadio {
    .BaseToggleBox {
      border-radius: 50%;
    }
  }
  &.true {
    .BaseToggleBox {
      background-color: color('teal',.9);
    }
    .BaseToggleBoxCheck {
      width: 76.67%;
      height: 76.67%;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: .62;
  }
}
.BaseToggleInner {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: -.38em;
  margin-right: -.38em;
  cursor: pointer;
  border-radius: .2em;
  width: calc(100% + .62em);
  &:hover {
    .BaseToggleBox {
      filter: brightness(1.1);
    }
  }

  .BaseToggle.inverted {
    &:hover {
      background-color: color('white', .1);
    }
    input:focus + & {
      background-color: color('white', .15);
    }
  }
  &:active {
    .BaseToggleBox {
      filter: brightness(.9);
    }
  }
  &.disabled {
    color: color('gray');
  }
}
.BaseToggleLabel {
  display: block;
  flex: 1 1 auto;
  text-align: left;
  font-weight: 500;
  font-size: 1.4rem;
  @media #{$desktop-lg} {
    font-size: 1.6rem;
  }
  a {
    color: color('dark');
    font-weight: 500;
    .inverted & {
      color: inherit;
    }
  }
}
.BaseToggleBox {
  position: relative;
  box-sizing: content-box;
  display: block;
  flex: 0 0 auto;
  background-color: color('white',.69);
  border: 1px solid rgba(10, 29, 42, 0.33);
  border-radius: 5em;
  margin-right: .5em;
  transform: translateZ(0);
  .inverted & {
    background-color: color('white',.2);
    border: 1px solid rgba(10, 29, 42, 0.33);
  }
}
.BaseToggleBoxMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 85%;
  letter-spacing: normal;
  font-size: 1rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
}
.BaseToggleBoxCheck {
  width: 75%;
  height: 75%;
  color: color('white');
}
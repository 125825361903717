@import '../../styles/base';

.VideoWrapper {
  appearance: none;
  border: none;
  background-color: color('dark', 0.5);
  border-radius: 3px;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 16 / 9;
  transform: translate3d(0,0,0);
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  video, .IframeVideoEmbed {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.rounded {
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    border-radius: .3em;

    @media #{$tablet} {
      border-radius: .5em;
    }
  }
}

.VideoWrapper__label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  cursor: pointer;
  svg {
    color: color('white');
  }
}

.VideoWrapperContainer.fullscreenOnly {
  z-index: 100000;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0A1D2A;

  .BaseButton {
    min-width: 2rem;
    padding: 1rem;
    @media #{$from-tablet} {
      padding: 1.5rem;
    }

    z-index: 100001;
    position: absolute;
    top: 0;
    right: 0;

    text-transform: uppercase;
    background-color: #1C89C4;
    border: none;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > div > span {
      margin-left: .8rem;
    }
  }

  .LoadingIndicator span {
    background-color: white;
  }

  .VideoCloseIcon {
    width: 9.5px;
    height: 9.5px;
    left: 1163px;
    top: 131px;

    border: 1px solid #FFFFFF;
  }

  .VideoWrapper {
    width: 100%;
    height: 100%;
  }
}
@import '../../styles/base';

.PageLogout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--AppHeight);
  color: color('teal');
  font-size: 2rem;

  .LoadingIndicator {
    margin-left: .1em;

    span {
      background-color: color('teal');
    }
  }
}
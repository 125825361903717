@import '../../styles/base';

.PageReportIndex {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  position: relative;

  .AppNavBar {
    flex: 0 0 auto;
  }

  footer {
    margin-top: auto;
  }

  .AppFooter {
    padding: 0;
    padding-top: size(lg);
    border: none;

    .AppFooterInner {
      @media #{$only-tablet} {
        flex-direction: column;

        >* {
          +* {
            margin-top: 1em;
          }
        }
      }
    }

    .AppFooter__Content {
      @media #{$only-phone} {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

.PageReportIndexContent {
  display: flex;
  flex: 1 0 400px;
  position: relative;
  width: 100%;
  max-width: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;
  overflow: hidden;
  height: var(--AppHeight) ;


  .ReportIndexSidebar {
    border-right: 1px solid color('dark', .1);

    @media #{$tablet} {
      flex: 0 0 34rem;
    }

    @media #{$desktop-lg} {
      position: relative;
      flex: 0 0 36rem;
    }
  }

  .PageContent {
    .filterButton {
      display: flex;
      position: fixed;
      z-index: 10;
      top: $AppNavBarHeight;
      left: 0;
      padding: 1rem;
      border: none;
      transform: rotate(90deg) translateY(-100%);
      transform-origin: top left;
      clip-path: polygon(0 0, 90% 0, 100% 100%, 0% 100%);
      min-width: 7em;
      text-transform: uppercase;
      font-size: 1.2rem;

      svg {
        margin-right: .5rem;
        transform: rotate(-90deg);
      }

      @media #{$from-tablet-lg} {
        display: none;
      }
    }
    .filterButtonOpen {
      left: unset;
      // right: -2.25em;
      @media #{$upto-phone-md} {
        left: 0;
      }
      @media #{$phone-md} {
        // right: -6.5em;
        left: 0;
      }
      @media #{$phone-lg} {
        right: -2.45em;
      }
      @media #{$tablet} {
        right: 24.75em;
      }
      svg {
        transform: rotate(90deg);
      }
    }
  }
}
.PageBackdropLayer {
  top: 0;
}